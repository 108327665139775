<script setup lang="ts">
  import { isArray, isUndefined, toggleElementInArray } from '@borg/utils';
  import { MpBox, MpCheckbox, MpFloating, MpIcon, MpText } from '@borg/ui';
  import { Item } from '@borg/types';
  import { isItem } from '@/utils/filter';
  import { logger } from '@borg/core/utils/logger';

  type State = { mode: 'single'; modelValue?: Item } | { mode: 'multiple'; modelValue: Item[] };

  const props = defineProps<{
    modelValue?: Item | Item[];
    title: string;
    mode: 'single' | 'multiple';
    options: Item[];
  }>();

  const emit = defineEmits<{
    (event: 'update:modelValue', value: Item | Item[] | undefined): void;
  }>();

  const isOpen = ref(false);

  const state = computed<State>(() => {
    if (props.mode === 'single' && !(isArray(props.modelValue) && props.modelValue.length > 0)) {
      return {
        mode: 'single',
        modelValue: isArray(props.modelValue) ? undefined : props.modelValue,
      };
    }
    if (props.mode === 'multiple' && !isItem(props.modelValue)) {
      return {
        mode: 'multiple',
        modelValue: isUndefined(props.modelValue) ? [] : props.modelValue,
      };
    }
    const errorMessage = `${props.title} modelValue not compatible with mode ${props.mode}`;
    logger.error(errorMessage);
    throw new Error(errorMessage);
  });
  const isAnythingSelected = computed(() =>
    state.value.mode === 'single' ? !!state.value.modelValue : state.value.modelValue.length > 0,
  );
  const selectedText = computed(() => {
    let text = '';
    if (state.value.mode === 'single') {
      text = state.value.modelValue?.name || ' ';
    } else {
      text = state.value.modelValue.map((item) => item.name).join(', ');
    }

    if (text.length > props.title.length - 3) {
      text = text.slice(0, props.title.length - 3) + '...';
    }

    return text;
  });

  function toggleOptionsVisibility() {
    isOpen.value = !isOpen.value;
  }

  function toggleOption(option: Item) {
    if (state.value.mode === 'single') {
      state.value.modelValue?.id === option.id
        ? emit('update:modelValue', undefined)
        : emit('update:modelValue', option);
    } else {
      const nextModelValue = toggleElementInArray(
        state.value.modelValue,
        option,
        (o) => o.id === option.id,
      );
      emit('update:modelValue', nextModelValue);
    }
  }

  function isSelected(option: Item) {
    return state.value.mode === 'single'
      ? state.value.modelValue?.id === option.id
      : state.value.modelValue.some((o) => o.id === option.id);
  }
</script>

<template>
  <MpFloating
    :model-value="isOpen"
    :show-arrow="false"
    strategy="absolute"
    placement="bottom-start"
    @outside-click="isOpen = false"
  >
    <div
      class="select"
      @click="toggleOptionsVisibility"
    >
      <div class="select__inner">
        <MpText
          :class="{ 'select__title': true, 'select__title--float': isAnythingSelected }"
          no-margin
          no-wrap
        >
          {{ title }}
        </MpText>
        <MpText
          class="select__title--invisible"
          no-margin
          no-wrap
        >
          {{ title }}
        </MpText>
        <Transition>
          <MpText
            v-if="isAnythingSelected"
            class="select__selected"
            as="div"
            no-margin
            no-wrap
          >
            {{ selectedText }}
          </MpText>
        </Transition>
      </div>
      <MpIcon
        :class="{ 'select__btn': true, 'select__btn--open': isOpen }"
        icon="chevron_down"
        size="sm"
      />
    </div>
    <template #content>
      <MpBox
        as="ul"
        shadow="medium"
        surface="60"
        class="dropdown"
        hide-scrollbar
        :style="{
          maxHeight: '22rem',
        }"
      >
        <li
          v-for="option in options"
          :key="option.id"
          :class="{ 'dropdown__option': true, 'dropdown__option--selected': isSelected(option) }"
          @click="toggleOption(option)"
        >
          <MpCheckbox :model-value="isSelected(option)" />
          <MpText no-margin>
            {{ option.name }}
          </MpText>
        </li>
      </MpBox>
    </template>
  </MpFloating>
</template>

<style scoped lang="scss">
  .select {
    display: flex;
    align-items: flex-end;
    gap: var(--mp-space-20);
    cursor: pointer;

    &__inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;
      height: 48px;
      overflow: hidden;
    }

    &__title {
      position: absolute;
      transition:
        transform 150ms,
        color 150ms;
      transform-origin: center left;

      &--float {
        transform: translateY(-90%) scale(0.75);
      }

      &--invisible {
        visibility: hidden;
      }
    }

    &__btn {
      transform: rotate(0deg);
      transition: transform 300ms ease;

      &--open {
        transform: rotate(180deg);
      }
    }

    &__selected {
      position: absolute;
      text-overflow: ellipsis;
    }
  }

  .dropdown {
    margin: 0;
    min-width: 200px;
    border: 1px solid var(--mp-color-grey-200);
    padding: var(--mp-space-50) 0;
    border-radius: 10px;
    overflow-y: auto;

    @include dark-mode {
      border: 1px solid var(--mp-color-grey-500);
    }

    &__option {
      display: flex;
      align-items: center;
      padding: var(--mp-space-20) var(--mp-space-50);
      gap: var(--mp-space-30);
      color: var(--mp-color-grey-800);
      cursor: pointer;

      @include dark-mode {
        color: var(--mp-color-grey-200);
      }

      &--selected {
        color: var(--mp-color-grey-black);
      }

      &:hover {
        color: var(--mp-color-grey-black);

        @include dark-mode {
          color: var(--mp-color-grey-400);
        }
      }

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  .v-enter-active {
    transition: opacity 350ms ease;
    transition-delay: 150ms;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
